import navigationOffCanvas from './components/navigation_off_canvas'
import transparentHeader from './components/transparent_header'
import youtubeVideo from './components/youtube_video'
import ocul_tar from './components/ocultar_flecha_up'
import headerVideo from './components/header_video'
import googleMaps from './components/google_maps'
import contactForm from './components/contact_form'
//import lightbox from './components/lightbox'



navigationOffCanvas()
transparentHeader()
youtubeVideo()
ocul_tar ()
//lightbox ()

//Solamente si la barra  de direcciones incluye la palabra contacto
//se ejecutaran etas tres funciones
if ( location.pathname.includes('contacto') ) {
  headerVideo()
  googleMaps()
  contactForm()
}
