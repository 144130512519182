export default function ocul_tar () {
    const d = document,
        w = window,
        ocult_flecha = d.querySelector('.iconsub')

    function scroll_ocul () {
        let scrollTop = w.pageYOffset || d.documentElement.scrollTop
        if (scrollTop > 400) {
            ocult_flecha.classList.remove('su'),
            ocult_flecha.classList.add('s')
        }else{
            ocult_flecha.classList.remove('s'),
            ocult_flecha.classList.add('su')

        }
    }
    d.addEventListener('DOMContentLoaded', scroll_ocul)
    w.addEventListener('scroll', scroll_ocul, false)



}